export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110'),
	() => import('./nodes/111'),
	() => import('./nodes/112'),
	() => import('./nodes/113'),
	() => import('./nodes/114'),
	() => import('./nodes/115'),
	() => import('./nodes/116'),
	() => import('./nodes/117'),
	() => import('./nodes/118'),
	() => import('./nodes/119'),
	() => import('./nodes/120'),
	() => import('./nodes/121'),
	() => import('./nodes/122'),
	() => import('./nodes/123'),
	() => import('./nodes/124'),
	() => import('./nodes/125'),
	() => import('./nodes/126'),
	() => import('./nodes/127'),
	() => import('./nodes/128'),
	() => import('./nodes/129'),
	() => import('./nodes/130'),
	() => import('./nodes/131'),
	() => import('./nodes/132'),
	() => import('./nodes/133'),
	() => import('./nodes/134'),
	() => import('./nodes/135'),
	() => import('./nodes/136'),
	() => import('./nodes/137'),
	() => import('./nodes/138'),
	() => import('./nodes/139'),
	() => import('./nodes/140'),
	() => import('./nodes/141'),
	() => import('./nodes/142'),
	() => import('./nodes/143'),
	() => import('./nodes/144'),
	() => import('./nodes/145'),
	() => import('./nodes/146'),
	() => import('./nodes/147'),
	() => import('./nodes/148'),
	() => import('./nodes/149'),
	() => import('./nodes/150'),
	() => import('./nodes/151'),
	() => import('./nodes/152'),
	() => import('./nodes/153'),
	() => import('./nodes/154'),
	() => import('./nodes/155'),
	() => import('./nodes/156'),
	() => import('./nodes/157'),
	() => import('./nodes/158'),
	() => import('./nodes/159'),
	() => import('./nodes/160'),
	() => import('./nodes/161'),
	() => import('./nodes/162'),
	() => import('./nodes/163'),
	() => import('./nodes/164'),
	() => import('./nodes/165'),
	() => import('./nodes/166'),
	() => import('./nodes/167'),
	() => import('./nodes/168'),
	() => import('./nodes/169'),
	() => import('./nodes/170'),
	() => import('./nodes/171'),
	() => import('./nodes/172'),
	() => import('./nodes/173'),
	() => import('./nodes/174'),
	() => import('./nodes/175'),
	() => import('./nodes/176'),
	() => import('./nodes/177'),
	() => import('./nodes/178'),
	() => import('./nodes/179'),
	() => import('./nodes/180'),
	() => import('./nodes/181'),
	() => import('./nodes/182'),
	() => import('./nodes/183'),
	() => import('./nodes/184'),
	() => import('./nodes/185'),
	() => import('./nodes/186'),
	() => import('./nodes/187'),
	() => import('./nodes/188'),
	() => import('./nodes/189'),
	() => import('./nodes/190'),
	() => import('./nodes/191'),
	() => import('./nodes/192'),
	() => import('./nodes/193'),
	() => import('./nodes/194'),
	() => import('./nodes/195'),
	() => import('./nodes/196'),
	() => import('./nodes/197'),
	() => import('./nodes/198'),
	() => import('./nodes/199'),
	() => import('./nodes/200'),
	() => import('./nodes/201'),
	() => import('./nodes/202'),
	() => import('./nodes/203'),
	() => import('./nodes/204'),
	() => import('./nodes/205'),
	() => import('./nodes/206'),
	() => import('./nodes/207'),
	() => import('./nodes/208'),
	() => import('./nodes/209'),
	() => import('./nodes/210'),
	() => import('./nodes/211'),
	() => import('./nodes/212'),
	() => import('./nodes/213'),
	() => import('./nodes/214'),
	() => import('./nodes/215'),
	() => import('./nodes/216'),
	() => import('./nodes/217'),
	() => import('./nodes/218'),
	() => import('./nodes/219'),
	() => import('./nodes/220'),
	() => import('./nodes/221'),
	() => import('./nodes/222'),
	() => import('./nodes/223'),
	() => import('./nodes/224'),
	() => import('./nodes/225'),
	() => import('./nodes/226'),
	() => import('./nodes/227'),
	() => import('./nodes/228'),
	() => import('./nodes/229'),
	() => import('./nodes/230'),
	() => import('./nodes/231'),
	() => import('./nodes/232'),
	() => import('./nodes/233'),
	() => import('./nodes/234'),
	() => import('./nodes/235'),
	() => import('./nodes/236'),
	() => import('./nodes/237'),
	() => import('./nodes/238'),
	() => import('./nodes/239'),
	() => import('./nodes/240'),
	() => import('./nodes/241'),
	() => import('./nodes/242'),
	() => import('./nodes/243'),
	() => import('./nodes/244'),
	() => import('./nodes/245'),
	() => import('./nodes/246'),
	() => import('./nodes/247'),
	() => import('./nodes/248'),
	() => import('./nodes/249'),
	() => import('./nodes/250'),
	() => import('./nodes/251'),
	() => import('./nodes/252'),
	() => import('./nodes/253'),
	() => import('./nodes/254'),
	() => import('./nodes/255'),
	() => import('./nodes/256'),
	() => import('./nodes/257'),
	() => import('./nodes/258'),
	() => import('./nodes/259'),
	() => import('./nodes/260'),
	() => import('./nodes/261'),
	() => import('./nodes/262'),
	() => import('./nodes/263'),
	() => import('./nodes/264'),
	() => import('./nodes/265'),
	() => import('./nodes/266'),
	() => import('./nodes/267'),
	() => import('./nodes/268'),
	() => import('./nodes/269'),
	() => import('./nodes/270'),
	() => import('./nodes/271'),
	() => import('./nodes/272'),
	() => import('./nodes/273'),
	() => import('./nodes/274'),
	() => import('./nodes/275'),
	() => import('./nodes/276'),
	() => import('./nodes/277'),
	() => import('./nodes/278'),
	() => import('./nodes/279'),
	() => import('./nodes/280'),
	() => import('./nodes/281'),
	() => import('./nodes/282'),
	() => import('./nodes/283'),
	() => import('./nodes/284'),
	() => import('./nodes/285'),
	() => import('./nodes/286'),
	() => import('./nodes/287'),
	() => import('./nodes/288'),
	() => import('./nodes/289'),
	() => import('./nodes/290'),
	() => import('./nodes/291'),
	() => import('./nodes/292'),
	() => import('./nodes/293'),
	() => import('./nodes/294'),
	() => import('./nodes/295'),
	() => import('./nodes/296'),
	() => import('./nodes/297'),
	() => import('./nodes/298'),
	() => import('./nodes/299'),
	() => import('./nodes/300'),
	() => import('./nodes/301'),
	() => import('./nodes/302'),
	() => import('./nodes/303'),
	() => import('./nodes/304'),
	() => import('./nodes/305'),
	() => import('./nodes/306'),
	() => import('./nodes/307'),
	() => import('./nodes/308'),
	() => import('./nodes/309'),
	() => import('./nodes/310'),
	() => import('./nodes/311'),
	() => import('./nodes/312'),
	() => import('./nodes/313'),
	() => import('./nodes/314'),
	() => import('./nodes/315'),
	() => import('./nodes/316'),
	() => import('./nodes/317'),
	() => import('./nodes/318'),
	() => import('./nodes/319'),
	() => import('./nodes/320'),
	() => import('./nodes/321'),
	() => import('./nodes/322'),
	() => import('./nodes/323'),
	() => import('./nodes/324'),
	() => import('./nodes/325'),
	() => import('./nodes/326'),
	() => import('./nodes/327'),
	() => import('./nodes/328'),
	() => import('./nodes/329')
];

export const server_loads = [];

export const dictionary = {
		"/": [2],
		"/blog": [3],
		"/business-cases": [4],
		"/business-cases/account-management": [5],
		"/business-cases/electronic-health-records": [6],
		"/business-cases/grow-your-online-presence": [7],
		"/business-cases/lab-manager": [8],
		"/business-cases/reception": [9],
		"/business-cases/security-and-backups": [10],
		"/business-cases/treatment-posts": [11],
		"/business-cases/treatment-report": [12],
		"/contributing": [13],
		"/documentation": [14],
		"/documentation/creating-pages": [15],
		"/documentation/creating-pages/frontmatter": [16],
		"/documentation/creating-pages/images-and-other-assets": [17],
		"/documentation/creating-pages/linking-to-headings": [18],
		"/documentation/creating-pages/linking-to-other-pages": [19],
		"/documentation/creating-pages/markdown": [20],
		"/documentation/creating-pages/naming-conventions": [21],
		"/documentation/creating-pages/site-structure": [22],
		"/documentation/styleguide": [23],
		"/patients": [24],
		"/patients/logging-in": [25],
		"/patients/patient-registration": [26],
		"/patients/portal": [27],
		"/patients/portal/appointments": [28],
		"/patients/portal/manage-profile": [29],
		"/patients/portal/manage-profile/change-password": [30],
		"/patients/portal/manage-profile/edit": [31],
		"/patients/portal/patient-feedback": [32],
		"/patients/portal/quotes": [33],
		"/patients/portal/quotes/signing-quotes": [34],
		"/patients/portal/reports": [35],
		"/patients/portal/reports/dentist-letter": [36],
		"/patients/portal/reports/photos-and-xrays": [37],
		"/patients/portal/reports/quote": [38],
		"/patients/portal/reports/treatment-plan-and-general-notes": [39],
		"/patients/portal/treatment-progress": [40],
		"/patients/portal/virtual-appointments": [41],
		"/referring-doctors": [42],
		"/referring-doctors/manage-profile": [43],
		"/referring-doctors/oral-hygiene": [44],
		"/referring-doctors/refer-a-patient": [45],
		"/referring-doctors/reports": [46],
		"/referring-doctors/timelines": [47],
		"/staff": [48],
		"/staff/admin": [49],
		"/staff/admin/accounts-upload": [50],
		"/staff/admin/appointment-stats": [51],
		"/staff/admin/dashboards": [52],
		"/staff/admin/inbound-referrals": [53],
		"/staff/admin/medical-aid": [54],
		"/staff/admin/oral-hygiene-check": [55],
		"/staff/admin/oral-hygiene-feedback": [56],
		"/staff/admin/outbound-referrals": [57],
		"/staff/admin/patient-login": [58],
		"/staff/admin/patient-reviews": [59],
		"/staff/admin/payments": [60],
		"/staff/appointments": [61],
		"/staff/appointments/all-appointments": [62],
		"/staff/appointments/appointment-day-sheet": [63],
		"/staff/appointments/appointment-grid": [64],
		"/staff/appointments/appointment-grid/book-appointment": [65],
		"/staff/appointments/appointment-grid/cancel-appointment": [66],
		"/staff/appointments/appointment-grid/change-date": [67],
		"/staff/appointments/appointment-grid/resend-reminder": [68],
		"/staff/appointments/appointment-grid/reserve-time-slots": [69],
		"/staff/appointments/appointment-history": [70],
		"/staff/appointments/appointment-search": [71],
		"/staff/appointments/missed-appointments": [72],
		"/staff/appointments/patient-queue": [73],
		"/staff/appointments/virtual-appointments": [74],
		"/staff/appointments/virtual-appointments/virtual-appointment-queue": [75],
		"/staff/getting-started": [76],
		"/staff/hardware": [77],
		"/staff/hardware/sticker-printer": [78],
		"/staff/knowledge-base": [79],
		"/staff/lab-manager": [80],
		"/staff/lab-manager/lab-manager-patient-profile": [81],
		"/staff/lab-manager/models-in-progress": [82],
		"/staff/lab-manager/models-waiting": [83],
		"/staff/logging-in": [84],
		"/staff/managing-patients": [85],
		"/staff/managing-patients/appointments": [86],
		"/staff/managing-patients/archiving-patients": [87],
		"/staff/managing-patients/consent-and-letters": [88],
		"/staff/managing-patients/deleting-patients": [89],
		"/staff/managing-patients/invoices-and-quotes": [90],
		"/staff/managing-patients/invoices-and-quotes/generating-invoices": [91],
		"/staff/managing-patients/new-patient-form": [92],
		"/staff/managing-patients/new-patient-form/accept-terms": [93],
		"/staff/managing-patients/new-patient-form/gender-and-birth-date": [94],
		"/staff/managing-patients/new-patient-form/medical-aid-information": [95],
		"/staff/managing-patients/new-patient-form/medical-and-dental-history": [96],
		"/staff/managing-patients/new-patient-form/patient-information": [97],
		"/staff/managing-patients/new-patient-form/person-responsible-for-account-documents": [99],
		"/staff/managing-patients/new-patient-form/person-responsible-for-account": [98],
		"/staff/managing-patients/new-patient-form/referring-doctor": [100],
		"/staff/managing-patients/new-patient-form/signature": [101],
		"/staff/managing-patients/new-patient-form/summary": [102],
		"/staff/managing-patients/new-patient-form/user-information": [103],
		"/staff/managing-patients/other-xray-uploads": [104],
		"/staff/managing-patients/patient-entries": [105],
		"/staff/managing-patients/patient-feedback": [106],
		"/staff/managing-patients/patient-information": [107],
		"/staff/managing-patients/patient-information/account-number": [108],
		"/staff/managing-patients/patient-information/external-file-link": [109],
		"/staff/managing-patients/patient-menu": [110],
		"/staff/managing-patients/patient-menu/create-patient": [111],
		"/staff/managing-patients/patient-menu/elastics-manager": [112],
		"/staff/managing-patients/patient-menu/merging-patients": [113],
		"/staff/managing-patients/patient-menu/new-patient-photos-manager": [114],
		"/staff/managing-patients/patient-menu/new-patient-xray-manager": [115],
		"/staff/managing-patients/patient-menu/patient-profile-manager": [116],
		"/staff/managing-patients/patient-menu/post-manager": [117],
		"/staff/managing-patients/patient-self-check-in-portal": [118],
		"/staff/managing-patients/quotes": [119],
		"/staff/managing-patients/quotes/archiving-quotes": [120],
		"/staff/managing-patients/quotes/creating-quotes": [121],
		"/staff/managing-patients/quotes/editing-quotes": [122],
		"/staff/managing-patients/quotes/emailing-quotes": [123],
		"/staff/managing-patients/quotes/quote-settings-and-configuration": [124],
		"/staff/managing-patients/quotes/viewing-quotes": [125],
		"/staff/managing-patients/records": [126],
		"/staff/managing-patients/records/photos": [127],
		"/staff/managing-patients/records/photos/discussion": [128],
		"/staff/managing-patients/records/photos/edit-or-delete-images": [129],
		"/staff/managing-patients/records/photos/navigation": [130],
		"/staff/managing-patients/records/photos/upload-images": [131],
		"/staff/managing-patients/records/xrays": [132],
		"/staff/managing-patients/records/xrays/new-patient-xrays": [133],
		"/staff/managing-patients/report-conversions": [134],
		"/staff/managing-patients/report-conversions/marking-a-report-conversion-as-not-converted": [135],
		"/staff/managing-patients/report-conversions/report-conversion-notes": [136],
		"/staff/managing-patients/report-conversions/report-conversion-reminders": [137],
		"/staff/managing-patients/report-conversions/statuses": [138],
		"/staff/managing-patients/reports": [139],
		"/staff/managing-patients/reports/flagged-posts": [140],
		"/staff/managing-patients/reports/journey": [141],
		"/staff/managing-patients/reports/journey/categories": [142],
		"/staff/managing-patients/reports/journey/clinical-notes": [143],
		"/staff/managing-patients/reports/journey/elastics": [144],
		"/staff/managing-patients/reports/journey/general-notes": [145],
		"/staff/managing-patients/reports/journey/patient-notes": [146],
		"/staff/managing-patients/reports/journey/photo-observations": [147],
		"/staff/managing-patients/reports/journey/surgery": [148],
		"/staff/managing-patients/reports/journey/teeth": [149],
		"/staff/managing-patients/reports/journey/treatment-notes": [150],
		"/staff/managing-patients/reports/journey/treatment-setup": [151],
		"/staff/managing-patients/reports/journey/xrays": [152],
		"/staff/managing-patients/reports/other-xray-review": [153],
		"/staff/managing-patients/reports/report-waiting": [154],
		"/staff/managing-patients/reports/reports-dashboard": [155],
		"/staff/managing-patients/reports/reports-dashboard/new-report": [156],
		"/staff/managing-patients/reports/reports-dashboard/quotes": [157],
		"/staff/managing-patients/reports/reports-dashboard/report-summary": [158],
		"/staff/managing-patients/reports/reports-dashboard/report-summary/dentist-letter": [159],
		"/staff/managing-patients/reports/reports-dashboard/report-summary/photos-and-xrays": [160],
		"/staff/managing-patients/reports/reports-dashboard/report-summary/treatment-plan-and-general-notes": [161],
		"/staff/managing-patients/reports/reports-dashboard/report-summary/welcome-letter": [162],
		"/staff/managing-patients/reports/reports-dashboard/send-report": [163],
		"/staff/managing-patients/reports/reports-dashboard/upload-report": [164],
		"/staff/managing-patients/reports/submitted-reports": [165],
		"/staff/managing-patients/search": [166],
		"/staff/managing-patients/signed-quotes": [167],
		"/staff/managing-patients/signed-quotes/archiving-signed-quotes": [168],
		"/staff/managing-patients/signed-quotes/disputed-signed-quotes": [169],
		"/staff/managing-patients/signed-quotes/sending-confirmation-reminders": [170],
		"/staff/managing-patients/signed-quotes/signing-quotes": [171],
		"/staff/managing-patients/signed-quotes/uploading-signed-quotes": [172],
		"/staff/managing-patients/signed-quotes/viewing-signed-quotes": [173],
		"/staff/managing-patients/timeline": [174],
		"/staff/managing-patients/timeline/posts": [175],
		"/staff/managing-patients/timeline/posts/complete": [176],
		"/staff/managing-patients/timeline/posts/elastics-and-breakages": [177],
		"/staff/managing-patients/timeline/posts/lab-post": [178],
		"/staff/managing-patients/timeline/posts/refer-to-doctor": [179],
		"/staff/managing-patients/timeline/posts/wires": [180],
		"/staff/managing-patients/timeline/posts/xero-charges": [181],
		"/staff/managing-patients/timeline/posts/xero-charges/payment": [182],
		"/staff/managing-patients/timeline/posts/xero-charges/xero-charges": [183],
		"/staff/managing-patients/timeline/posts/xero-charges/xero-contracts": [184],
		"/staff/managing-patients/timeline/posts/xero-charges/xero-contracts/send-statements": [185],
		"/staff/managing-patients/timeline/qr-code": [186],
		"/staff/managing-patients/timeline/treatment-setups": [187],
		"/staff/managing-patients/treatment-posts": [188],
		"/staff/navigation": [189],
		"/staff/profile": [190],
		"/staff/settings": [191],
		"/staff/settings/annotation-templates": [192],
		"/staff/settings/appointment-settings": [193],
		"/staff/settings/appointment-settings/appointment-resources": [194],
		"/staff/settings/appointment-settings/appointment-resources/adding-appointment-resources": [195],
		"/staff/settings/appointment-settings/appointment-resources/archiving-appointment-resources": [196],
		"/staff/settings/appointment-settings/appointment-resources/editing-appointment-resources": [197],
		"/staff/settings/appointment-settings/appointment-resources/editing-appointment-resources/resource-off-days": [198],
		"/staff/settings/appointment-settings/appointment-resources/editing-appointment-resources/resource-schedules": [199],
		"/staff/settings/appointment-settings/appointment-resources/editing-appointment-resources/resource-services": [200],
		"/staff/settings/appointment-settings/appointment-settings": [201],
		"/staff/settings/appointment-settings/appointment-settings/appointment-services": [202],
		"/staff/settings/appointment-settings/locations-and-doctor-settings": [203],
		"/staff/settings/appointment-settings/locations-and-doctor-settings/doctors": [204],
		"/staff/settings/appointment-settings/locations-and-doctor-settings/filter-appointments": [205],
		"/staff/settings/appointment-settings/locations-and-doctor-settings/location-general-settings": [206],
		"/staff/settings/appointment-settings/locations-and-doctor-settings/location-per-doctor": [207],
		"/staff/settings/appointment-settings/locations-and-doctor-settings/location-schedule": [208],
		"/staff/settings/company-settings": [209],
		"/staff/settings/company-settings/appointment-goals": [210],
		"/staff/settings/company-settings/company-details": [211],
		"/staff/settings/company-settings/patient-and-booking-portal": [212],
		"/staff/settings/company-settings/subscription-settings": [213],
		"/staff/settings/company-settings/users": [214],
		"/staff/settings/company-settings/users/adding-users": [215],
		"/staff/settings/company-settings/users/managing-individual-users": [216],
		"/staff/settings/company-settings/users/permissions": [217],
		"/staff/settings/company-settings/users/roles": [218],
		"/staff/settings/drawing-and-signature-setup": [219],
		"/staff/settings/drawing-and-signature-setup/consent-form": [220],
		"/staff/settings/drawing-and-signature-setup/drawing-tool": [221],
		"/staff/settings/icd10-codes": [222],
		"/staff/settings/invoicing": [223],
		"/staff/settings/invoicing/billable-item-groups": [224],
		"/staff/settings/invoicing/billable-items": [225],
		"/staff/settings/invoicing/invoice-generator-templates": [226],
		"/staff/settings/new-patient-discussion": [227],
		"/staff/settings/new-patient-form": [228],
		"/staff/settings/patient-timeline-settings": [229],
		"/staff/settings/patient-timeline-settings/elastics-wear-key": [230],
		"/staff/settings/patient-timeline-settings/new-patient-photos-timeline-display": [231],
		"/staff/settings/patient-timeline-settings/oral-hygiene-email": [232],
		"/staff/settings/patient-timeline-settings/patient-timeline-communications": [233],
		"/staff/settings/patient-timeline-settings/posts": [234],
		"/staff/settings/patient-timeline-settings/posts/all-teeth-numbers": [235],
		"/staff/settings/patient-timeline-settings/posts/clinical-notes-during-treatment": [236],
		"/staff/settings/patient-timeline-settings/posts/next-appointment": [237],
		"/staff/settings/patient-timeline-settings/posts/notes-for-patient": [238],
		"/staff/settings/patient-timeline-settings/posts/oral-hygiene": [239],
		"/staff/settings/patient-timeline-settings/posts/wires": [240],
		"/staff/settings/patient-timeline-settings/treatment-setup": [241],
		"/staff/settings/referring-doctors": [242],
		"/staff/settings/referring-doctors/doctor": [243],
		"/staff/settings/referring-doctors/services": [244],
		"/staff/settings/referring-doctors/suburbs": [245],
		"/staff/settings/referring-doctors/type-of-doctor": [246],
		"/staff/settings/report-conversions": [247],
		"/staff/settings/report-settings": [248],
		"/staff/settings/report-settings/clinical-notes": [249],
		"/staff/settings/report-settings/elastics": [250],
		"/staff/settings/report-settings/elastics/elastics-colour": [251],
		"/staff/settings/report-settings/elastics/elastics-notes": [252],
		"/staff/settings/report-settings/elastics/elastics-time": [253],
		"/staff/settings/report-settings/elastics/elastics-tooth-number-io": [254],
		"/staff/settings/report-settings/general-notes": [255],
		"/staff/settings/report-settings/notes-to-clinical-team": [256],
		"/staff/settings/report-settings/patient-notes": [257],
		"/staff/settings/report-settings/photo-observations": [258],
		"/staff/settings/report-settings/photo-observations/anterior-bite": [259],
		"/staff/settings/report-settings/photo-observations/bite-mm": [260],
		"/staff/settings/report-settings/photo-observations/bruxing": [261],
		"/staff/settings/report-settings/photo-observations/cross-bite": [262],
		"/staff/settings/report-settings/photo-observations/dental-malocclusion-left": [263],
		"/staff/settings/report-settings/photo-observations/dental-malocclusion-right": [264],
		"/staff/settings/report-settings/photo-observations/diastema": [265],
		"/staff/settings/report-settings/photo-observations/frenectomy": [266],
		"/staff/settings/report-settings/photo-observations/frontal-open-bite": [267],
		"/staff/settings/report-settings/photo-observations/frontal-tongue-thrust": [268],
		"/staff/settings/report-settings/photo-observations/gingival-recession": [269],
		"/staff/settings/report-settings/photo-observations/gum-and-bone-support": [270],
		"/staff/settings/report-settings/photo-observations/head-posture": [271],
		"/staff/settings/report-settings/photo-observations/lateral-open-bite": [272],
		"/staff/settings/report-settings/photo-observations/lateral-tongue-thrust": [273],
		"/staff/settings/report-settings/photo-observations/lip-closed-in-repose": [274],
		"/staff/settings/report-settings/photo-observations/lip-strain": [275],
		"/staff/settings/report-settings/photo-observations/lips-supported-by-teeth": [277],
		"/staff/settings/report-settings/photo-observations/lips": [276],
		"/staff/settings/report-settings/photo-observations/lower-centre-lines-to-face-midline": [278],
		"/staff/settings/report-settings/photo-observations/mallampati-airway-classification": [279],
		"/staff/settings/report-settings/photo-observations/naso-labial-angle": [280],
		"/staff/settings/report-settings/photo-observations/occlusal-plane": [281],
		"/staff/settings/report-settings/photo-observations/palate-shape": [282],
		"/staff/settings/report-settings/photo-observations/patient-cooperative": [283],
		"/staff/settings/report-settings/photo-observations/photo-observations-required-and-hidden": [284],
		"/staff/settings/report-settings/photo-observations/photograph-observations-pre-tick": [286],
		"/staff/settings/report-settings/photo-observations/photograph-observations": [285],
		"/staff/settings/report-settings/photo-observations/posterior-bite": [287],
		"/staff/settings/report-settings/photo-observations/throat": [288],
		"/staff/settings/report-settings/photo-observations/tmd": [289],
		"/staff/settings/report-settings/photo-observations/tongue-tie": [290],
		"/staff/settings/report-settings/photo-observations/tonsils": [291],
		"/staff/settings/report-settings/photo-observations/tooth-display-on-talking-and-smiling": [292],
		"/staff/settings/report-settings/photo-observations/upper-and-lower-jaw-development": [293],
		"/staff/settings/report-settings/photo-observations/upper-and-lower-jaw-symmetry": [294],
		"/staff/settings/report-settings/photo-observations/upper-centre-lines-to-face-midline": [295],
		"/staff/settings/report-settings/photo-uploads": [296],
		"/staff/settings/report-settings/photo-uploads/intra-oral-photo-upload-settings": [297],
		"/staff/settings/report-settings/photo-uploads/photo-upload-settings": [298],
		"/staff/settings/report-settings/surgery-notes": [299],
		"/staff/settings/report-settings/teeth-instructions": [300],
		"/staff/settings/report-settings/teeth-instructions/tooth-numbering-quadrant": [301],
		"/staff/settings/report-settings/teeth-instructions/tooth-observation-template": [302],
		"/staff/settings/report-settings/teeth-instructions/tooth-observation-titles": [303],
		"/staff/settings/report-settings/treatment-category": [304],
		"/staff/settings/report-settings/treatment-category/category-name": [305],
		"/staff/settings/report-settings/treatment-category/category-quote": [306],
		"/staff/settings/report-settings/treatment-category/code-assignment": [307],
		"/staff/settings/report-settings/treatment-category/icd-10": [308],
		"/staff/settings/report-settings/treatment-category/optional-costs": [309],
		"/staff/settings/report-settings/treatment-category/post-treatment-fee": [310],
		"/staff/settings/report-settings/treatment-category/pre-treatment-fee": [311],
		"/staff/settings/report-settings/treatment-category/treatment-code": [312],
		"/staff/settings/report-settings/treatment-category/treatment-report": [313],
		"/staff/settings/report-settings/treatment-plan-notes": [314],
		"/staff/settings/report-settings/treatment-setup": [315],
		"/staff/settings/report-settings/xray-observations": [316],
		"/staff/settings/system-files-upload-and-download": [317],
		"/staff/settings/virtual-appointment-settings": [318],
		"/staff/settings/virtual-appointment-settings/appointment-filter": [319],
		"/staff/settings/virtual-appointment-settings/examples": [320],
		"/staff/settings/virtual-appointment-settings/general-settings": [321],
		"/staff/settings/virtual-appointment-settings/patient-landing-page": [322],
		"/staff/settings/whatsapp-settings": [323],
		"/staff/settings/xero-accounting-integration-settings": [324],
		"/staff/settings/xero-accounting-integration-settings/branded-theme": [325],
		"/staff/settings/xero-accounting-integration-settings/getting-started-with-xero": [326],
		"/staff/settings/xero-accounting-integration-settings/invoice-status": [327],
		"/staff/settings/xero-accounting-integration-settings/manage-xero-charges": [328],
		"/staff/settings/xero-accounting-integration-settings/xero-invoice-settings": [329]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';